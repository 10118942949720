import { useEffect } from 'react'
import './style.css'
import { collection, onSnapshot, orderBy, query } from 'firebase/firestore'
import { db } from '../../firebase'
import BoardListItem from './boardlistitem'
import Pagination from '../../pagination/pagination'
import { usePagination } from '../../pagination'

export interface BoardItem {
  id:string
  title:string
  article:string
  boardTitleImage: string | null
  favoriteCount: number
  commentCount: number
  viewCount: number
  createdAt:number
  username:string
  userId:string
  writerProfileImage: string | null
}






export default function MainBottom () {


  const { currentPage, setCurrentPage, currentSection, setCurrentSection, viewList, setTotalList, viewPageList, totalSection } = usePagination<BoardItem>(9);

  useEffect(() => {
    const q = query(collection(db, "articles"), orderBy("createdAt", "desc"));
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const articles = querySnapshot.docs.map((doc) => {
        const {boardTitleImage, title, article, favoriteCount, commentCount, viewCount, writerProfileImage, createdAt, userId, username} = doc.data();
        return {
          id: doc.id,
          title,
          article,
          boardTitleImage,
          favoriteCount,
          commentCount,
          viewCount,
          writerProfileImage,
          createdAt,
          userId,
          username,
        };
      });
      setTotalList(articles);
    });

    return () => unsubscribe(); 
  }, []);
    

  return (
  <div style={{padding:'40px', backgroundColor: 'rgba(0, 0, 0, 0.05)'}}>
  <div className="columns is-half is-offset-one-quarter" style={{paddingBottom:'1vh'}}>
    <div className="column main-bottom-title" style={{ display:'flex' , justifyContent:'center'}}>
      전체 게시물
    </div>
  </div>
  <div className='columns is-multiline'>
    {viewList.map((article, index) => (
      <div className="column is-one-third" key={article.id} style={{paddingBottom: '4vh', display:'flex', justifyContent:'center'}}>
        <BoardListItem {...article} />
      </div>
    ))}
  </div>
  <div className="columns">
    <div className='column' style={{ display:'flex', justifyContent:'center'}}>
      <Pagination
        currentPage={currentPage}
        currentSection={currentSection}
        setCurrentPage={setCurrentPage}
        setCurrentSection={setCurrentSection}
        viewPageList={viewPageList}
        totalSection={totalSection}
      />
    </div>
  </div>
</div>
)
}