import { useState } from 'react';
import defaultProfileImage from '../../assets/image/profile.png'
import './commentlistitem.css'
import dayjs from 'dayjs';

interface commentListItemProps {

    id: string;
    commentId: string;
    username: string;
    profileImage: string | null;
    createdAt: string;
    comment1: string;
    onDelete: (id: string) => Promise<void>;
    currentUserId?: string; // 현재 로그인한 사용자의 ID
}


//           components: Comment List Item 컴포넌트        //
export default function CommentItem({ id, commentId, username, profileImage, createdAt, comment1, onDelete, currentUserId }: commentListItemProps) {

    const [showMore, setShowMore] = useState<boolean>(false);

    const getCommentDatetimeFormat = () => {
        if(!comment1) return '';
        const date = dayjs(createdAt)
        return date.format('YYYY.MM.DD HH:mm:ss');
      }

      const onMoreButtonClickHandler = () => {
        setShowMore(!showMore);
      }
    
    
    //          render: Comment LIst item 랜더링          //
      return (
        <div className='comment-list-item'>
            <div className='comment-list-item-top'>
                <div className='comment-list-item-profile-box'>
                    <div className='comment-list-item-profile-image' style={{ backgroundImage: `url(${profileImage ? profileImage : defaultProfileImage})` }}></div>
                </div>
                <div className='comment-list-item-nickname'>{username}</div>
                <div className='comment-list-item-divider'>{` |`}</div>
                <div className='comment-list-item-time'>{getCommentDatetimeFormat()}</div>
                {currentUserId === id && (
                <div onClick={onMoreButtonClickHandler}>
                <div className='comment-list-item-delete' onClick={()=> onDelete(commentId)}>{'삭제'}</div>
                </div>
                )}
            </div>
            <div className='comment-list-item-main'>
                <div className='comment-list-item-content'>{comment1}</div>
            </div>
        </div>
      )
    }