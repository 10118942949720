import './style.css'


export default function MainTop () {
    return (
      <div>
      <section className="hero is-success is-fullheight">
        <div className="hero-body">
          <div className="container has-text-centered">
            <p className="title">
              Make out Hill:)
            </p>
          </div>
        </div>
        
        
        <div className="hero-foot">
          <nav className="tabs is-boxed is-fullwidth">
            <div className="container">
              <ul>
                <li className="is-active"><a>Latest Posting</a></li>
                <li><a>Algorithm</a></li>
                <li><a>ETC</a></li>
              </ul>
            </div>
          </nav>
        </div>
      </section>
      </div>
      )
}