/* eslint-disable jsx-a11y/anchor-is-valid */
import { useNavigate } from "react-router-dom";
import { auth } from "../firebase";
import './style.css'
import { useEffect, useState } from "react";
import { User } from "firebase/auth";


export default function MyPageLogOutButton () {


  const [user, setUser] = useState<User | null>(null);
  
  const navigate = useNavigate()


  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((currentUser) => {
      setUser(currentUser);
    });

    return () => unsubscribe();
  }, []);

  
  const onSignOutButtonClickHandler = async () => {
            await auth.signOut()
            alert('로그아웃 되었습니다')
            navigate('/')
            window.location.reload();
  };


  if (user) {
  return (
  <>
  <a className="navbar-item" style={{ fontSize: "0.9rem",}} >
    <div onClick={onSignOutButtonClickHandler} style={{ cursor: "pointer", fontSize: "0.9rem"}}>Logout</div>
  </a>
  </>
  )}

  return <a className="navbar-item" href="/signin" style={{ fontSize: "0.9rem" }} >Login</a>;
  };

