/* eslint-disable jsx-a11y/anchor-is-valid */
import { useNavigate } from "react-router-dom";
import './style.css'
import MyPageLogOutButton from "./headerbutton";
import { useEffect, useRef, useState } from "react";
import defaultProfileImage from './profile.png'
import { auth } from "../firebase";
import { User } from "firebase/auth";




const SearchButton = () => {
  const navigate = useNavigate();
  const searchInputRef = useRef<HTMLInputElement>(null);

  const handleSearch = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault(); // 폼의 기본 제출 이벤트를 방지
    const searchWord = searchInputRef.current?.value;
    if (searchWord) {
      navigate(`/search/${encodeURIComponent(searchWord)}`);
    }
  };

  return (
    <>
    <form onSubmit={handleSearch} >
    <div className="narvar-item" style={{paddingRight:'20px'}}>
          <div className="field has-addons">
            <div className="control">
              <input className="input" type="search" ref={searchInputRef} placeholder="search"/>
            </div>
            <div className="control">
              <button type="submit" className="button is-success">Search</button>
            </div>
          </div>
    </div>
    </form>
    </>
  );
};

export default function Header() {
  const navigate = useNavigate();
  
  const [user, setUser] = useState<User | null>(null);
  const [userProfile, setUserProfile] = useState({
    displayName: '',
    photoURL: ''
  });





  const [isActive, setIsActive] = useState(false);
  const handleBurgerClick = () => {
    setIsActive(!isActive); // 토글 상태 변경
  };


  useEffect(() => {
    // Auth 상태 변경을 감지하는 리스너를 등록
    const unsubscribe = auth.onAuthStateChanged(user => {
      setUser(user);
      if (user) {
        setUserProfile({
          displayName: user.displayName || '',
          photoURL: user.photoURL || defaultProfileImage
        });
      } else {
        // 로그아웃 상태일 때의 처리, 예를 들어 로그인 페이지로 리디렉션 등
        setUserProfile({
          displayName: '',
          photoURL: ''
        });
        // navigate('/signin'); // 필요에 따라 사용
      }
    });

    // 컴포넌트가 언마운트될 때 리스너를 제거
    return () => unsubscribe();
  }, [navigate]);


  const onMyPageButtonClickHandler = () => {
    if(user && user.email) {
      navigate(`/user/${user.email}`);
    }
  };
  const onHomePageButtonClickHandler = () => {
      navigate(`/`);
  };



return (
<>
<div className="hero-head">
  <header className="navbar" >
    <div className="container">
      <div className="navbar-brand">
        <a className="navbar-item" style={{ color: '#FF9600', fontSize: "1rem" }} onClick={onHomePageButtonClickHandler} >
          <img src="/tokamak.png" alt="" style={{ width: "28px"}} />
          Tokamak Laboratory
        </a>
        <span className={`navbar-burger ${isActive ? 'is-active' : ''}`} onClick={handleBurgerClick} data-target="navbarMenuHeroC">
          <span></span>
          <span></span>
          <span></span>
        </span>
      </div>
      <div id="navbarMenuHeroC" className={`navbar-menu ${isActive ? 'is-active' : ''}`}>
        <div className="navbar-end" style={{alignItems:'center'}} >
          <div className="narvar-item" style={{paddingRight:'20px'}}>
            <SearchButton/>
          </div>
          {user &&
            <button className="button is-white" onClick={onMyPageButtonClickHandler}>
                <img src={userProfile.photoURL || defaultProfileImage} alt="Profile" style={{ borderRadius: '50%', width: '28px', height: '28px' }} />
            </button>
          }
            <MyPageLogOutButton />
        </div>
      </div>
    </div>
  </header>
</div>


</>
);
}