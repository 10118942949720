// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth"
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";


// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyB7karSR1Z7uH_Yxxaw9ghrY3LG9_URLUg",
  authDomain: "tokamak-be799.firebaseapp.com",
  projectId: "tokamak-be799",
  storageBucket: "tokamak-be799.appspot.com",
  messagingSenderId: "459459038805",
  appId: "1:459459038805:web:29ecd9ca8837e69aa3cedc"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);
export const storage = getStorage(app)
export const db = getFirestore(app)
