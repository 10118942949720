import { GithubAuthProvider, signInWithPopup } from "firebase/auth"
import { auth } from "../../firebase"
import { useNavigate } from "react-router-dom"

export default function GithubButton() {
    const navigate = useNavigate()
    const onClick = async () => {

        try{
        
        const provider = new GithubAuthProvider()
        await signInWithPopup(auth, provider)
        navigate("/");
        } catch (error) {
            console.error(error)
            alert(error)
        }
    }
    return (
    <>
    <div className='github-button' onClick={onClick}>
        <div className="github-profile-box me-3">
            <div className="github-profile-image"></div>
        </div>
    {` Sign in with Github`}</div>
    </>
    )
}