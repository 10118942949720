import './style.css'
import Googlebtn from './googlebtn';
import GithubButton from './githubbtn';
import { Col, Container, Row } from 'react-bootstrap';



export default function Signin() {



    
return (
  <Container fluid className=" text-white p-3 d-flex justify-content-center align-items-center" style={{minHeight:'80vh', backgroundColor:'#000000'}}>
    <Row className='w-100 justify-content-center' >
        <Col className='d-flex align-items-center justify-content-center'>
          <div className='auth-jumbotron-contents '>
            <div className='auth-logo-icon'></div>
            <div className='auth-jumbotron-text-box'>
              <div className='auth-jumbotron-text'>{'MAKE OUT HILL:)'}</div>
              <div className='auth-jumbotron-text'>{''}</div>
            </div>
          </div>
        </Col>

        <Col className='d-flex align-items-center justify-content-center'>
          <div className='auth-card'>
          <div className='auth-card-box'>
            <div className='auth-card-top'>
              <div className='auth-card-title-box'>
                <div className='auth-card-title'>{'로그인'}</div>
              </div>

              <Googlebtn/>
              <GithubButton/>
              <div className='auth-description-box'>
              </div>
            </div>
          </div>
          </div>
        </Col>

    </Row>
  </Container>
);
}