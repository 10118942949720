import { useEffect, useRef, useState } from 'react';
import './userprofile.css'
import { auth, db } from '../../firebase';
import defaultProfileImage from '../../assets/image/profile.png'
import { useNavigate, useParams } from 'react-router-dom';
import { updateProfile } from 'firebase/auth';
import { getDownloadURL, getStorage, ref, uploadBytes } from 'firebase/storage';
import { collection, getDocs, orderBy, query, where } from 'firebase/firestore';
import BoardListItem from '../main/boardlistitem';
import { usePagination } from '../../pagination';
import Pagination from '../../pagination/pagination';
import { Col, Container } from 'react-bootstrap';
import { Row } from 'react-bootstrap';

export interface BoardItem {
    id:string
    title:string
    article:string
    boardTitleImage: string | null
    favoriteCount: number
    commentCount: number
    viewCount: number
    createdAt:number
    username:string
    userId:string
    writerProfileImage: string | null
  }


export default function Userprofile () {



    const { currentPage, setCurrentPage, currentSection, setCurrentSection, viewList, setTotalList, viewPageList, totalSection } = usePagination<BoardItem>(5);

    const [isMyPage, setMyPage] = useState<boolean>(false);
    const [profileImage, setProfileImage] = useState<string | null >(null);
    const [isNicknameChange, setNicknameChange] = useState<boolean>(false);
    const [newNickname, setNewNickname] = useState<string>('');
    const imageInputRef = useRef<HTMLInputElement | null>(null);
    const navigate = useNavigate();
    const { email } = useParams();
    const user = auth.currentUser;


    

    useEffect(() => {
        if (user && user.email === email) {
            setMyPage(true);
            setProfileImage(user.photoURL || defaultProfileImage);
            setNewNickname(user.displayName || '');
        } else {
            navigate('/signin')
        }
    }, [email, user]);

    
    // 사용자의 게시물을 불러오는 함수
    const fetchUserPosts = async () => {
        if (!user) return;
            
        const q = query(collection(db, "articles"), where("userId", "==", user.uid), orderBy("createdAt", "desc"));
        const querySnapshot = await getDocs(q);
        const posts = querySnapshot.docs.map(doc => {
        const {boardTitleImage ,title, article, favoriteCount, commentCount, viewCount, writerProfileImage, createdAt, userId, username} = doc.data()
        return {
                title,
                article,
                boardTitleImage,
                favoriteCount,
                commentCount,
                viewCount,
                writerProfileImage,
                createdAt, 
                userId, 
                username, 
                id:doc.id
                }
        })
        setTotalList(posts);
        }


    useEffect(() => {
        fetchUserPosts()
    },[])
    


    const onProfileBoxClickHandler = () => {
        if (!isMyPage) return;
        if (!imageInputRef.current) return;
        imageInputRef.current.click();
      };
    

    const onNicknameChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        setNewNickname(e.target.value);
    };

    const onNicknameEditButtonClickHandler = () => {
        setNicknameChange(!isNicknameChange)
    }

    const onSaveNicknameChangeHandler = async () => {
        if (user) {
            try {
                await updateProfile(user, {
                    displayName: newNickname,
                });
                alert('닉네임이 변경되었습니다.');
                setNicknameChange(false); 
            } catch (error) {
                console.error('닉네임 변경 오류:', error);
                alert('닉네임 변경 중 오류가 발생했습니다.');
            }
        }
    };

    const onwriteclickhandler = () => {
        navigate('/write')
    }

    // 프로필 이미지 변경 핸들러
    const onProfileImageChangeHandler = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files?.[0] && user) {
        const file = e.target.files[0];
        const storage = getStorage();
        const storageRef = ref(storage, `profile/${user.uid}`);
        try {
            // 파일을 Storage에 업로드
            const snapshot = await uploadBytes(storageRef, file);
            // 업로드된 파일의 URL 가져오기
            const photoURL = await getDownloadURL(snapshot.ref);
            // Auth 사용자 프로필 업데이트
            await updateProfile(user, {
                photoURL,
            });
            // UI 업데이트
            setProfileImage(photoURL);
        } catch (error) {
            console.error("프로필 이미지 업로드 실패:", error);
            alert("프로필 이미지 업데이트 중 오류가 발생했습니다. 다시시도 해주세요.");
        }
    }
};

const canWrite = user?.email === 'wlghks3939@gmail.com';

    return(
        (
    <Container style={{ paddingBottom:'10vh'}}>
        <Row>
            <Col style={{ display: 'flex', alignItems: 'center'}}>
                {isMyPage ?
                <div className='user-top-my-profile-image-box ms-3' onClick={onProfileBoxClickHandler}>
                {profileImage !== null ?
                <div className='user-top-profile-image' style={{ backgroundImage: `url(${profileImage})` }}></div> :
                <div className='icon-box-large'>
                    <div className='icon image-box-white-icon'></div>
                </div>
                } 
                <input ref={imageInputRef} type="file" accept='image/*' style={{ display:'none' }} onChange={onProfileImageChangeHandler}/>
                </div> :
                <div className='user-top-profile-image-box' style={{ backgroundImage: `url(${profileImage ? profileImage : defaultProfileImage})`}}></div>
                }
                    <div className='user-top-info-box'>
                        <div className='user-top-info-nickname-box'>
                        {isMyPage ? 
                        <>
                        {isNicknameChange ? 
                        <>
                        <input className='user-top-info-nickname-input' type='text' value={newNickname} onChange={onNicknameChangeHandler}/>
                        <button className='black-button' onClick={onSaveNicknameChangeHandler}>변경</button>
                        </> :
                        <div className='user-top-info-nickname'>{user?.displayName}</div>
                        }
                        <div className='icon-button' onClick={onNicknameEditButtonClickHandler}>
                        <div className='icon edit-icon'></div>
                        </div>
                        </> :
                        <div className='user-top-info-nickname'>{user?.displayName}</div>
                        }
                        </div>
                        <div className='user-top-info-email'>{user?.email}</div>
                      </div>
                </Col>
            </Row>


        <Row>
                <Col>
                    <div className='user-bottom-title'>{isMyPage ? '내 게시물 ' : '게시물 '}</div>
                    <div className='user-bottom-contents-box'>
                    {viewList.length === 0 ?
                    <div className='user-bottom-contents-nothing'>{'게시물이 없습니다.'}</div> :
                    <div className='user-bottom-contents'>
                    {viewList.map(post => <BoardListItem key={post.id} {...post} 
                    />)}
                    </div>
                    }
                    {canWrite && (
                    <div className='user-bottom-side-box'>
                        <div className='user-bottom-side-card' onClick={onwriteclickhandler}>
                            <div className='user-bottom-side-container'>
                            <>
                            <div className='icon-box'>
                            <div className='icon edit-icon'></div>
                            </div>
                            <div className='user-bottom-side-text' >{'글쓰기'}</div>
                            </> 
                            
                            </div>
                        
                        </div>
                    </div>
                    )}
            </div>
                <div className='user-bottom-pagination-box'>
                <Pagination
                currentPage={currentPage}
                currentSection={currentSection}
                setCurrentPage={setCurrentPage}
                setCurrentSection={setCurrentSection}
                viewPageList={viewPageList}
                totalSection={totalSection}
                />
                </div>
            </Col>
        </Row>
    </Container>
        )
    )
}