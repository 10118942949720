import { useNavigate } from 'react-router-dom';
import './style.css'

export default function Footer() {

    const navigate = useNavigate();

    
    const onInstaIconButtonClickHandler = () =>{
        window.open('https://www.instagram.com/tokamaklaboratory?igsh=YTQwZjQ0NmI0OA%3D%3D&utm_source=qr');
    };
    
    
    const onYoutubeIconButtonClickHandler = () =>{
        window.open('https://www.youtube.com/channel/UC9YFGyPzxACnyhrdP22Pr_w');
    };

    const onHomeButtonClickhandler = () => {
        navigate('/')
    } 
    
    
      return (
        <>
        <footer className="footer" style={{backgroundColor: 'rgba(55, 55, 55, 1)' }}>
        
        <div className="columns" style={{ paddingTop:'5vh'}}>
            <div className="column" style={{ paddingLeft:'5vh'}}>
                <a style={{ color: '#FFFFFF', fontSize: "1.2rem", cursor:'pointer' }} href='/' >
                <img alt="" width="30" height="30" src="/tokamak.png" onClick={onHomeButtonClickhandler} />{' '}
                Tokamak Laboratory
                </a>
                <div style={{ fontSize: '0.9rem', color: '#FFFFFF', paddingLeft:"7px"}}>{'Copyright ⓒ 2024 TokamakLaboratory. All Right Reserved.'}</div>
            </div>
            <div className="column"></div>
            <div className="column">
                <div style={{ display: 'flex', paddingLeft:'5vh'}}>
                    <div style={{ color: '#FFFFFF' , fontSize:'1rem', paddingRight:'3vh'}}>{'wlghks3939@gmail.com'}</div>
                        <div style={{paddingRight:'3vh'}}>
                            <div className='icon-button'>
                                <div className='insta-icon' onClick={onInstaIconButtonClickHandler}></div>
                            </div>
                        </div>
                        <div>
                            <div className='icon-button'>
                                <div className='youtube-icon' onClick={onYoutubeIconButtonClickHandler}></div>
                            </div>
                        </div>
                </div>
            </div>
        </div>
        </footer>

        </>
      )
    }