import '@toast-ui/editor/dist/toastui-editor-viewer.css';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';
import defaultProfileImage from '../../assets/image/profile.png';
import './boardlistitem.css';
import gray from './gray.png';
import { BoardItem } from './mainbottom';


export const BOARD_DETAIL_PATH = (docRefId: number | string) => `/detail/${docRefId}`;

export default function BoardListItem ({ title, article, boardTitleImage, favoriteCount, commentCount, viewCount, writerProfileImage, createdAt, userId, username, id}:BoardItem ) {

    const navigate = useNavigate();
    const removeMarkdownImages = (markdown: string) => {
        const imageRegex = /!\[.*?\]\(.*?\)/g;
        return markdown.replace(imageRegex, '');
    };  
    
    const truncateString = (str: string, num: number): string => {
        return str.length > num ? `${str.slice(0, num)}...` : str;
      };

    // 이미지를 제거한 후, 문자열 길이를 제한
    const articleWithoutImages = truncateString(removeMarkdownImages(article), 70);

    const getWriteDatetimeFormat = () => {
        
        const date = dayjs(createdAt)
        return date.format('YYYY.MM.DD HH:mm:ss');
      }
    
    const onClick = () => {
        navigate(BOARD_DETAIL_PATH(id));
    }

    
    
    
    
    return (
        <>

    <div className="card" onClick={onClick} style={{ cursor:'pointer', borderRadius: '5%', width:'450px', overflow:'hidden'}}>
        <div className="card-image" >
            <figure className="image is-4by3">
            <img
            src={boardTitleImage || gray}
            alt=""
            />
            </figure>
        </div>
        <div className="card-content">
          <div className="media">
            <div className="media-content">
              <p className="title is-6">{truncateString(title, 50)}</p>
            </div>
          </div>

          <div className="content" style={{ color: 'rgba(0, 0, 0, 0.7)', fontSize: '12.5px', fontWeight: 400, lineHeight: '140%'}}>
            <div style={{ paddingBottom:'10px'}}>
            {articleWithoutImages}
            </div>
          <div style={{ color: 'rgba(0, 0, 0, 0.7)', fontSize: '12px', fontWeight: 400, lineHeight: '140%', paddingBottom:'10px' }}>{`조회수 ${viewCount} · 좋아요 ${favoriteCount} · 댓글 ${commentCount}`}</div>
          <span style={{ color: 'rgba(0, 0, 0, 0.7)', fontSize: '12px', fontWeight: 400, lineHeight: '140%'}}>{getWriteDatetimeFormat()}</span>
              <div style={{ display:'flex', paddingTop:'10px'}}>  
              <div className="image is-24x24">
              <img
              src={writerProfileImage ? writerProfileImage : defaultProfileImage}
              alt=""
              />
              </div>
              <span style={{ color: 'rgba(0, 0, 0, 0.7)', fontSize: '13px', fontWeight: 500, lineHeight: '140%', paddingTop:'3px'}}>{username}</span>
              </div>
          </div>
        </div>
    </div>










    </>
    )
}