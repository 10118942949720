import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { collection, getDocs, orderBy, query } from 'firebase/firestore';
import { db } from '../../firebase';
import { usePagination } from '../../pagination';
import Pagination from '../../pagination/pagination';
import BoardListItem from '../main/boardlistitem';
import './search.css'
import { Col, Container, Row } from 'react-bootstrap';

// BoardItem 인터페이스 정의
export interface BoardItem {
  id: string;
  title: string;
  article: string;
  boardTitleImage: string | null;
  favoriteCount: number;
  commentCount: number;
  viewCount: number;
  createdAt: number;
  username: string;
  userId: string;
  writerProfileImage: string | null;
}

const Search: React.FC = () => {
    const { searchWord } = useParams<{ searchWord: string }>();
    const {
      currentPage,
      setCurrentPage,
      currentSection,
      setCurrentSection,
      viewList,
      setTotalList,
      viewPageList,
      totalSection,
    } = usePagination<BoardItem>(5);
  
    useEffect(() => {
      const fetchData = async () => {
        const q = query(collection(db, "articles"), orderBy("createdAt", "desc"));
        const querySnapshot = await getDocs(q);
        const articles: BoardItem[] = [];
  
        querySnapshot.forEach((doc) => {
            const data = doc.data() as Omit<BoardItem, 'id'>;
            articles.push({
              id: doc.id,
              ...data
            });
          });
  
        // 검색어에 따라 필터링된 게시글 설정
        const filteredArticles = articles.filter(article =>
          article.title.toLowerCase().includes(searchWord?.toLowerCase() || '') ||
          article.article.toLowerCase().includes(searchWord?.toLowerCase() || '')
        );
  
        setTotalList(filteredArticles); // 필터링된 게시글을 Pagination 훅에 전달
      };
  
      fetchData();
    }, [searchWord, setTotalList]);

  return (
    <Container style={{ paddingBottom:'10vh'}}>
      <Row>
        <Col>
        {viewList.length > 0 ? (
          <div className='search-contents'>
            {viewList.map(article => (
              <BoardListItem key={article.id} {...article} />
            ))}
          </div>
        ) : (
          <div className='search-contents-nothing'>검색 결과가 없습니다.</div>
        )}


        {viewList.length > 0 && (
          <div className='search-pagination-box'>
            <Pagination
              currentPage={currentPage}
              currentSection={currentSection}
              setCurrentPage={setCurrentPage}
              setCurrentSection={setCurrentSection}
              viewPageList={viewPageList}
              totalSection={totalSection}
            />
          </div>
        )}
        </Col>
      </Row>
    </Container>
  );
};

export default Search;