import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom'
import './boarddetail.css'
import { auth, db, storage } from '../../firebase';
import { addDoc, collection, deleteDoc, doc, getDoc, getDocs, onSnapshot, orderBy, query, updateDoc, where } from 'firebase/firestore';
import { Viewer } from '@toast-ui/react-editor';
import defaultProfileImage from '../../assets/image/profile.png';
import dayjs from 'dayjs';
import CommentItem from './commentlistitem';
import { ref as storageRef, deleteObject } from "firebase/storage";
import { usePagination } from '../../pagination';
import Pagination from '../../pagination/pagination';
import { Row } from 'react-bootstrap';
import 'prismjs/themes/prism.css';
import '@toast-ui/editor-plugin-code-syntax-highlight/dist/toastui-editor-plugin-code-syntax-highlight.css';
import Prism from 'prismjs';
import codeSyntaxHighlight from '@toast-ui/editor-plugin-code-syntax-highlight';


export interface Contents {
    title: string
    article: string
    category: string
    boardTitleImage: string|null
    favoriteCount: number
    commentCount: number
    viewCount: number
    writerProfileImage: string | null
    createdAt: number
    username: string
    userId: string

}

export interface Comment {
    id: string;
    comment1: string;
    createdAt: any;
    username: string;
    userId: string;
    ProfileImage: string | null;
}



export default function BoardDetail () {
    
    const { docRefId } = useParams<{ docRefId: string }>();
    const [boardDetail, setBoardDetail] = useState<Contents | null>(null);
    const { currentPage, setCurrentPage, currentSection, setCurrentSection, viewList, viewPageList, totalSection, setTotalList } = usePagination<Comment>(10);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const navigate = useNavigate();
    

    const user = auth.currentUser;

    const [showMore, setShowMore] = useState<boolean>(false);
    const [isFavorite, setFavorite] = useState<boolean>(false);
    const [comment, setComment] = useState<string>('');



    //날짜
    const getWriteDatetimeFormat = () => {
        if(!boardDetail) return '';
        const date = dayjs(boardDetail.createdAt)
        return date.format('YYYY.MM.DD HH:mm');
      }


    
    
    const onMoreButtonClickHandler = () => {
        setShowMore(!showMore);
    }

    //게시물 불러오기
    useEffect(() => {
        const fetchBoardDetail = async () => {
            if (!docRefId) return;
            setIsLoading(true);
            try {
                const docRef = doc(db, "articles", docRefId);
                const docSnap = await getDoc(docRef);
                if (docSnap.exists()) {
                    setBoardDetail(docSnap.data() as Contents);
                } else {
                    alert("게시물이 존재하지 않습니다.");
                    navigate('/');
                }
            } catch (error) {
                console.error("Error fetching document: ", error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchBoardDetail();
    }, [docRefId, navigate]);

    // 댓글 실시간 불러오기
    useEffect(() => {
        if (docRefId) {
            const q = query(collection(db, "comments"), where("postId", "==", docRefId), orderBy("createdAt", "desc"));
            
            const unsubscribe = onSnapshot(q, (querySnapshot) => {
                const commentsData = querySnapshot.docs.map((doc) => ({
                    id: doc.id,
                    ...doc.data()   
                })) as Comment[]; // Casting the result as Comment[]
                setTotalList(commentsData);
            });

            return () => unsubscribe();
        }
    }, [docRefId]);

    //조회수기능
    useEffect(() => {
        const fetchBoardDetail = async () => {
          if (!docRefId) return;
          setIsLoading(true);
          try {
            const docRef = doc(db, "articles", docRefId);
            const docSnap = await getDoc(docRef);
            if (docSnap.exists()) {
              const data = docSnap.data() as Contents;
              const newViewCount = data.viewCount + 1;
              await updateDoc(docRef, { viewCount: newViewCount });
              setBoardDetail({ ...data, viewCount: newViewCount });
            } else {
              alert("게시물이 존재하지 않습니다.");
              navigate('/');
            }
          } catch (error) {
            console.error("Error fetching document: ", error);
          } finally {
            setIsLoading(false);
          }
        };
    
        fetchBoardDetail();
      }, [docRefId, navigate]);


    //좋아요 기능
    const toggleFavorite = async () => {
        if (!user) {
          alert('로그인이 필요한 기능입니다.');
          navigate('/signin');
          return;
        }
        if (!docRefId) return;
      
        const likesRef = collection(db, "likes");
        const q = query(likesRef, where("userId", "==", user.uid), where("postId", "==", docRefId));
        const querySnapshot = await getDocs(q);
      
        if (querySnapshot.empty) {
          // 좋아요가 눌리지 않았다면 추가
          await addDoc(collection(db, "likes"), {
            userId: user.uid,
            postId: docRefId,
          });
          const newFavoriteCount = boardDetail ? boardDetail.favoriteCount + 1 : 1;
          await updateDoc(doc(db, "articles", docRefId), {
            favoriteCount: newFavoriteCount,
          });
          // TypeScript 오류를 방지하기 위해 분명한 null 체크
          setBoardDetail((currentBoardDetail) => {
            return currentBoardDetail ? { ...currentBoardDetail, favoriteCount: newFavoriteCount } : null;
          });
          setFavorite(true); // UI 상태 업데이트
        } else {
          // 이미 좋아요를 눌렀다면 좋아요 취소
          querySnapshot.forEach(async (document) => {
            await deleteDoc(doc(db, "likes", document.id));
          });
          const newFavoriteCount = boardDetail ? boardDetail.favoriteCount - 1 : 0;
          await updateDoc(doc(db, "articles", docRefId), {
            favoriteCount: newFavoriteCount,
          });
          // TypeScript 오류를 방지하기 위해 분명한 null 체크
          setBoardDetail((currentBoardDetail) => {
            return currentBoardDetail ? { ...currentBoardDetail, favoriteCount: newFavoriteCount } : null;
          });
          setFavorite(false); // UI 상태 업데이트
        }
      };


    const addcomment = async () => {
        if (!user) {
            alert('로그인이 필요한 기능입니다.');
            return;
        }
        if (comment.trim() === '') return;

        try {
            await addDoc(collection(db, "comments"), {
                comment1: comment,
                createdAt: Date.now(),
                postId: docRefId,
                userId: user.uid,
                username: user.displayName || '익명',
                ProfileImage: user.photoURL || null,
            });
            

            if (boardDetail && docRefId) {
                const newCommentCount = boardDetail.commentCount + 1;
                const docRef = doc(db, "articles", docRefId);
                await updateDoc(docRef, {
                    commentCount: newCommentCount
                });
                // 상태 업데이트로 UI 반영
                setBoardDetail({ ...boardDetail, commentCount: newCommentCount });
            }
            setComment('');
            
            // 댓글 추가 후 댓글 목록 새로고침
        } catch (error) {
            console.error("Error adding comment: ", error);
        }
    };

    const onFavoriteClickHandler = () => {
        toggleFavorite(); // 이 함수를 호출하여 좋아요 상태를 토글
    };

    const onCommentChangeHandler = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        setComment(e.target.value)
    } 


    // 게시글, 관련 이미지, 댓글 삭제 로직
    const deleteArticleAndRelatedData = async (docRefId: string) => {
      if (!docRefId) return;

      // 게시글 문서에서 imageUrls 배열 가져오기
      const docRef = doc(db, "articles", docRefId);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
          const { imageUrls } = docSnap.data() as { imageUrls: string[] };

          // Firestore에서 게시글 삭제
          await deleteDoc(docRef);

          // 연관된 모든 이미지 삭제
          if (imageUrls && imageUrls.length > 0) {
              const deleteImagePromises = imageUrls.map((imageUrl) => {
                  const imageRef = storageRef(storage, imageUrl);
                  return deleteObject(imageRef);
              });
              await Promise.all(deleteImagePromises);
          }

          // 연관된 모든 댓글 삭제
          const commentsQuery = query(collection(db, "comments"), where("postId", "==", docRefId));
          const querySnapshot = await getDocs(commentsQuery);
          const deleteCommentPromises = querySnapshot.docs.map((commentDoc) => deleteDoc(commentDoc.ref));

          // 연관된 모든 '좋아요' 삭제
          const likesQuery = query(collection(db, "likes"), where("postId", "==", docRefId));
          const likesSnapshot = await getDocs(likesQuery);
          const deleteLikePromises = likesSnapshot.docs.map((likeDoc) => deleteDoc(likeDoc.ref));
          await Promise.all(deleteLikePromises);


          await Promise.all(deleteCommentPromises);

          alert("게시글과 관련 데이터가 삭제되었습니다.");
          navigate("/");
      } else {
          alert("게시물이 존재하지 않습니다.");
      }
    };

    // 삭제 버튼 클릭 핸들러
    const handleDelete = async () => {
      if(user?.uid !== boardDetail?.userId) return;
      const confirmation = window.confirm("게시글을 삭제하시겠습니까?");
      if (confirmation && docRefId) {
          await deleteArticleAndRelatedData(docRefId);
      }
    };


    const handleEditButtonClick = () => {
      if(user?.uid !== boardDetail?.userId) return;

      navigate(`/write/${docRefId}?edit=true`);
    };




    // 댓글 삭제 함수
    const deleteComment = async (commentId: string) => {
      const currentUser = auth.currentUser;

      if (!currentUser) {
          alert("로그인이 필요합니다.");
          return;
      }

      const commentRef = doc(db, "comments", commentId);
      const commentSnap = await getDoc(commentRef);

      if (!commentSnap.exists()) {
          alert("댓글이 존재하지 않습니다.");
          return;
      }

      const commentData = commentSnap.data();

      if (commentData.userId !== currentUser.uid) {
          alert("삭제 권한이 없습니다.");
          return;
      }

      const isConfirmed = window.confirm('정말 삭제하시겠습니까?');
            if (!isConfirmed) {
            return; // 사용자가 취소를 선택하면 여기서 함수 실행을 중단
            }

      try {
          await deleteDoc(commentRef);

          if (boardDetail) {
              const updatedCommentCount = boardDetail.commentCount - 1;
              const docRef = doc(db, "articles", docRefId!);
              await updateDoc(docRef, { commentCount: updatedCommentCount });

              setBoardDetail({ ...boardDetail, commentCount: updatedCommentCount });
          }

          alert("댓글이 삭제되었습니다.");
      } catch (error) {
          console.error("댓글 삭제 중 오류가 발생했습니다: ", error);
      }
  };





    //로딩되는 동안
    if (isLoading) return <div></div>;

    return (
    <div className="container">
      <div className="content" style={{ paddingBottom:'10vh', paddingTop:'10vh', paddingLeft:'1vh', paddingRight:'1vh'}}>
        <div id='board-detail-top'>
            <div className='board-detail-top-header'>
                <div className='board-detail-title'>{boardDetail?.title}</div>
                <div className='board-detail-top-sub-box'>
              <div className='board-detail-write-info-box'>
                <div className='board-detail-writer-profile-image' style={{backgroundImage: `url(${boardDetail?.writerProfileImage ? boardDetail.writerProfileImage : defaultProfileImage})`}}></div>
                <div className='board-detail-writer-nickname' >{boardDetail?.username}</div>
                <div className='board-detail-info-divider'>{'|'}</div>
                <div className='board-detail-write-date'>{getWriteDatetimeFormat()}</div>
                <div className='board-detail-info-divider'>{'|'}</div>
                <div className='board-detail-bottom-button-text'>{`조회수 ${boardDetail?.viewCount}`}</div>
              </div>
              <div className='icon-button' onClick={onMoreButtonClickHandler}>
                <div className='icon more-icon'></div>
              </div>
                    {showMore && user?.uid === boardDetail?.userId &&
                    <div className='board-detail-more-box'>
                        <div className='board-detail-update-button' onClick={handleEditButtonClick}>{'수정'}</div>
                        <div className='divider'></div>
                        <div className='board-detail-delete-button' onClick={handleDelete}>{'삭제'}</div>
                    </div>
                    }
                </div>
            </div>
            <div className='divider'></div>
            <div className='board-detail-top-main'>
                <div className='board-detail-main-text'>
                  <Viewer 
                  initialValue={boardDetail?.article}
                  plugins={[[codeSyntaxHighlight, { highlighter: Prism }]]}/>
                </div>
                
            </div>
        </div>

        <div id='board-detail-bottom'>
            <div className='board-detail-bottom-button-box'>
                <div className='board-detail-bottom-button-group'>
                <div className='icon-button' onClick={onFavoriteClickHandler}>
                {isFavorite ? 
                    <div className='icon favorite-fill-icon'></div> :
                    <div className='icon favorite-light-icon'></div>
                }
            </div>
                <div className='board-detail-bottom-button-text'>{`좋아요 ${boardDetail?.favoriteCount}`}</div>
          </div>

          <div className='board-detail-bottom-button-group'>
            <div className='icon-button'>
                <div className='icon comment-icon'></div>
            </div>
            <div className='board-detail-bottom-button-text'>{`댓글 ${viewList.length} `}</div>
          </div>
            </div>
            
                <div className='board-detail-bottom-comment-box'>
                <div className='board-detail-bottom-comment-container'>
                <div className='board-detail-bottom-comment-title'>{'댓글 '}<span className='emphasis'>{`(${viewList.length})`}</span></div>
                <div className='board-detail-bottom-comment-list-container'>
                {viewList.map((item) => (
                <CommentItem 
                key={item.id} 
                commentId={item.id}
                id={item.userId}
                username={item.username} 
                profileImage={item.ProfileImage}
                createdAt={item.createdAt}
                comment1={item.comment1} 
                onDelete={deleteComment}
                currentUserId={auth.currentUser?.uid}
                />
                ))}
                </div>
                </div>
                {viewList.length > 0 && (
                <>
                <div className='divider'></div>
                <div className='board-detail-bottom-comment-pagination-box' style={{ paddingBottom:'10vh' }}>
                  <Pagination 
                  currentPage={currentPage}
                  currentSection={currentSection}
                  setCurrentPage={setCurrentPage}
                  setCurrentSection={setCurrentSection}
                  viewPageList={viewPageList}
                  totalSection={totalSection}
                  />
                </div>
                </>
                )}
                </div>
                {user !== null && 
                <div className='board-detail-bottom-comment-input-box'>
                <div className='board-detail-bottom-comment-input-container'>
                <textarea  className='board-detail-bottom-comment-textarea' placeholder='댓글을 작성해주세요.' onChange={onCommentChangeHandler} value={comment} />
                <div className='board-detail-bottom-comment-button-box'>
                <div className={comment === '' ? 'disable-button' : 'black-button'} onClick={addcomment}>{'댓글달기'}</div>
                </div>
                </div>
                </div>
                }
        </div>
      </div>
    </div>
    )
}