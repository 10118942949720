import React, { useEffect, useRef, useState } from "react";
import { Editor } from '@toast-ui/react-editor';
import '@toast-ui/editor/dist/toastui-editor.css';
import 'tui-color-picker/dist/tui-color-picker.css';
import '@toast-ui/editor-plugin-color-syntax/dist/toastui-editor-plugin-color-syntax.css';
import colorSyntax from '@toast-ui/editor-plugin-color-syntax';
import '@toast-ui/editor-plugin-code-syntax-highlight/dist/toastui-editor-plugin-code-syntax-highlight.css';
import Prism from "prismjs";
import 'prismjs/themes/prism.css';
import codeSyntaxHighlight from '@toast-ui/editor-plugin-code-syntax-highlight';
import { auth, db, storage } from '../../firebase';
import { deleteObject, getDownloadURL, ref as storageRef, uploadBytes } from 'firebase/storage';
import { addDoc, collection, doc, getDoc, updateDoc } from 'firebase/firestore';
import './write.css';
import { useNavigate, useParams } from "react-router-dom";




export default function BoardWrite() {
  const { docRefId } = useParams<{ docRefId: string }>();
  const navigate = useNavigate();
  const editorRef = useRef<Editor | null>(null);

  const [title, setTitle] = useState<string>("");
  const [article, setArticle] = useState<string>("");
  const [isLoading, setLoading] = useState<boolean>(false);
  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const [category, setCategory] = useState('');
  
  const user = auth.currentUser; // 현재 로그인한 사용자

  // 게시글의 첫 번째 이미지 URL 추출
  const extractFirstImageUrl = (markdown: string): string | null => {
    const match = markdown.match(/!\[.*?\]\((.*?)\)/);
    return match ? match[1] : null;
  };

  useEffect(() => {
    const fetchArticle = async () => {
      if (docRefId) {
        const docRef = doc(db, 'articles', docRefId);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          const data = docSnap.data();
          setTitle(data.title);
          setArticle(data.article);
          editorRef.current?.getInstance().setMarkdown(data.article);
          setIsEditMode(true);
        }
      }
    };

    fetchArticle();
  }, [docRefId]);

  const onTitleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setTitle(e.target.value);
  };

  const onChange = () => {
    const markdown = editorRef.current?.getInstance().getMarkdown();
    setArticle(markdown || "");
  };

  const handleCategoryChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setCategory(e.target.value);
  };


  const onUploadImage = async (blob: Blob | File, callback: (url: string, text: string) => void) => {
    if (!user) return;
    const fileName = `${user.uid}_${Date.now()}`;
    const imageRef = storageRef(storage, `articles/${user.uid}/${fileName}`);
    const snapshot = await uploadBytes(imageRef, blob);
    const url = await getDownloadURL(snapshot.ref);
    callback(url, "Uploaded image");
  };

  const extractImageUrls = (markdown: string): string[] => {
    const regex = /!\[.*?\]\((.*?)\)/g;
    let urls = [];
    let match;
    while ((match = regex.exec(markdown))) {
      urls.push(match[1]);
    }
    return urls;
  };

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (isLoading || !title || !article) return;
    const canWrite = user?.email === 'tokamaklab@gmail.com' || 'wlghks3939@gmail.com';
    if (!canWrite) { 
      alert('권한이 없습니다')
      navigate('/')
      return;
    }
    setLoading(true);

    try {
      const newImageUrls = extractImageUrls(article);
      const userProfileImage = user?.photoURL || null; // 사용자 프로필 이미지 URL
      const firstImageUrl = extractFirstImageUrl(article);

      if (isEditMode && docRefId) {
        const docRef = doc(db, "articles", docRefId);
        const oldDocSnap = await getDoc(docRef);
        if (oldDocSnap.exists()) {
          const oldImageUrls = extractImageUrls(oldDocSnap.data().article);
          const unusedUrls = oldImageUrls.filter(url => !newImageUrls.includes(url));
          unusedUrls.forEach(url => {
            const imageRef = storageRef(storage, url);
            deleteObject(imageRef);
          });
        }
        await updateDoc(docRef, { title, article, category, imageUrls: newImageUrls });
        navigate(`/detail/${docRefId}`);
      } else {
        const docRef = await addDoc(collection(db, "articles"), { 
          title,
          article,
          category,
          boardTitleImage: firstImageUrl,
          imageUrls: newImageUrls,
          favoriteCount: 0,
          commentCount: 0,
          viewCount: 0,
          writerProfileImage: userProfileImage,
          createdAt: Date.now(),
          username: user?.displayName,
          userId: user?.uid,
         });
        navigate(`/detail/${docRef.id}`);
      }
    } catch (error) {
      console.error("Error saving the article: ", error);
    } finally {
      setLoading(false);
    }
  }



  return (
    <form onSubmit={onSubmit}>
      <div id='board-write-wrapper'>
        <div className='board-write-container'>
          <div className='board-write-box'>
            <div className='board-write-title-box'>
              <textarea className='board-write-title-textarea' value={title} onChange={onTitleChange} rows={1} placeholder='제목을 입력해주세요'></textarea>
            </div>
            <div className='divider'></div>
            <div className='board-write-content-box'>
              <Editor
                ref={editorRef}
                previewStyle="vertical"
                initialValue=""
                height="840px"
                initialEditType="markdown"
                useCommandShortcut={true}
                onChange={onChange}
                placeholder=""
                plugins={[
                  colorSyntax,
                  [codeSyntaxHighlight, { highlighter: Prism }],
                  

                ]}
                hooks={{
                  addImageBlobHook: onUploadImage,
                }}
                
              />
            </div>
            {(title && article) ? 
            <button className='black-button'>{'업로드'}</button> : 
            <button className='disable-button'disabled>{'업로드'}</button>
            }
            
            <div>
              <select value={category} onChange={handleCategoryChange}>
              <option value="">선택</option>
              <option value="python">파이썬</option>
              <option value="algorithm">알고리즘</option>
              <option value="etc">기타</option>
              </select>
            </div>


          </div>
        </div>
      </div>
    </form>
  );
};
