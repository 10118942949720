import { useEffect, useState } from "react";

const usePagination = <T>(countPerPage: number) => {
    const [totalList, setTotalList] = useState<T[]>([]);
    const [viewList, setViewList] = useState<T[]>([]);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [totalPageList, setTotalPageList] = useState<number[]>([]);
    const [viewPageList, setViewPageList] = useState<number[]>([]);
    const [currentSection, setCurrentSection] = useState<number>(1);
    const [totalSection, setTotalSection] = useState<number>(1);

    useEffect(() => {
        const totalPage = Math.ceil(totalList.length / countPerPage);
        const newTotalPageList = Array.from({ length: totalPage }, (_, i) => i + 1);
        setTotalPageList(newTotalPageList);

        const newTotalSection = Math.ceil(totalPage / 10);
        setTotalSection(newTotalSection);

        // Ensure current page and section are reset to initial state or adjusted accordingly
        setCurrentPage(1);
        setCurrentSection(1);
    }, [totalList, countPerPage]);

    useEffect(() => {
        const startIdx = (currentPage - 1) * countPerPage;
        const endIdx = startIdx + countPerPage;
        setViewList(totalList.slice(startIdx, endIdx));
    }, [currentPage, totalList, countPerPage]);

    useEffect(() => {
        const startIdx = (currentSection - 1) * 10;
        const endIdx = startIdx + 10;
        setViewPageList(totalPageList.slice(startIdx, endIdx));
    }, [currentSection, totalPageList]);

    return {
        currentPage,
        setCurrentPage,
        currentSection,
        setCurrentSection,
        viewList,
        viewPageList,
        totalSection,
        setTotalList
    };
};

export default usePagination;