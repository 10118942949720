import { GoogleAuthProvider, signInWithPopup } from "firebase/auth"
import { auth } from "../../firebase"
import { useNavigate } from "react-router-dom"
import './style.css'

export default function Googlebtn() {
    const navigate = useNavigate()
    const onClick = async () => {

        try{

        const provider = new GoogleAuthProvider()
        await signInWithPopup(auth, provider)
        navigate("/");
        } catch (error) {
            console.error(error)
            alert(error)
        }
    }
    return (
    <>
    <div className='google-button' onClick={onClick}>
        <div className="google-profile-box me-3">
            <div className="google-profile-image"></div>
        </div>
         {` Sign in with Google`}</div>
    </>
    )
}